@tailwind base;

body {
  @apply bg-[#F7F8FC];
  @apply text-[#40406A];
  margin: 0;
  font-family: 'DM Sans', 'Hiragino Sans', -apple-system, BlinkMacSystemFont, 'Segoe UI', 'Roboto', 'Oxygen',
    'Ubuntu', 'Cantarell', 'Fira Sans', 'Droid Sans', 'Helvetica Neue',
    sans-serif;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
}

h1 {@apply text-4xl font-bold}
h2 {@apply text-3xl font-bold}
h3 {@apply text-2xl font-semibold}
h4 {@apply text-xl font-semibold}
h5 {@apply text-lg}
h6 {@apply text-base}

a {@apply text-[#2121EE]}

code {
  font-family: source-code-pro, Menlo, Monaco, Consolas, 'Courier New', monospace;
}

/* components */

.--cg-button {
  @apply w-60 max-w-xs h-10 font-semibold text-white text-base rounded-lg ease-out duration-300
}
.connected {
  --w3m-accent-fill-color: #40406A;
  --w3m-accent-color: rgb(255,255,255);
  --w3m-color-overlay: rgba(0,0,0, 0.1);
}

.--cg-nft-list {
  @apply flex py-3;
  background-image: linear-gradient(to right, rgba(176,176,193,1) 33%, rgba(255,255,255,0) 0%);
  background-position: bottom;
  background-size: 3px 1px;
  background-repeat: repeat-x;
  .--cg-nft-list-key {
    @apply capitalize w-1/3 font-semibold text-left ml-4 md:ml-8
  }
  .--cg-nft-list-value {
    @apply w-2/3 break-all
  }
}

.--cg-tooltip {
  @apply block relative z-10;
  p {
    @apply block absolute ease-out duration-300 rounded-md opacity-0 pointer-events-none
    w-60 sm:w-96 md:w-80 
    text-justify p-4 bottom-8 -right-4 bg-white;
    box-shadow: 0px 1px 4px rgba(0, 0, 0, 0.25);
    &::before{
      @apply w-0 h-0 absolute;
      z-index: 10;
      content: "";
      margin-left: 0;
      bottom: -14px;
      right: calc(1rem + 4px);
      box-sizing: border-box;
      border: 8px solid white;
      border-color: transparent transparent #fff #fff;
      transform-origin: 0 0;
      transform: rotate(-45deg);
    }
    &::after{
      @apply w-0 h-0 absolute;
      z-index: -10;
      content: "";
      margin-left: 0;
      bottom: -14px;
      right: calc(1rem + 4px);
      box-sizing: border-box;
      border: 8px solid #fff;
      border-color: transparent transparent #fff #fff;
      transform-origin: 0 0;
      transform: rotate(-45deg);
      box-shadow: -3px 3px 4px rgb(0 0 0 / 15%);
    }
  }
  &:hover {
    p { @apply opacity-90;}
  }
}

@tailwind components;
@tailwind utilities;
